import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './Header';
import LandingPage from './LandingPage';
import Blog from './Blog';
import Resume from './Resume';
import Services from './Services';
import BlogPost from './BlogPost';

const App: React.FC = () => {
    return (
        <Router>
            <Header />
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog/:slug" element={<BlogPost />} />
                <Route path="/resume" element={<Resume />} />
                <Route path="/services" element={<Services />} />
            </Routes>
        </Router>
    );
};

export default App;

