import React from 'react';
import { Link } from 'react-router-dom';
import './Blog.css';

interface BlogPost {
    slug: string;
    title: string;
    date: string;
    description: string;
}

interface PostIndex {
    posts: string[];
}

const Blog = () => {
    const [posts, setPosts] = React.useState<BlogPost[]>([]);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState<string | null>(null);

    React.useEffect(() => {
        const fetchPosts = async () => {
            try {
                // Fetch the index file
                const indexResponse = await fetch('/content/posts/index.json');
                if (!indexResponse.ok) {
                    throw new Error(`Failed to fetch index.json: ${indexResponse.statusText}`);
                }

                const indexData: PostIndex = await indexResponse.json();

                if (!indexData.posts || !Array.isArray(indexData.posts)) {
                    throw new Error('Invalid index.json format. Expected "posts" array.');
                }

                // Fetch and parse each markdown file
                const postPromises = indexData.posts.map(async (filename: string) => {
                    try {
                        const response = await fetch(`/content/posts/${filename}`);
                        if (!response.ok) {
                            console.error(`Failed to fetch ${filename}: ${response.statusText}`);
                            return null;
                        }

                        const text = await response.text();
                        console.log(`Content for ${filename}:`, text.slice(0, 100)); // Debug log

                        // Parse frontmatter
                        const frontmatterRegex = /^---\n([\s\S]*?)\n---\n/;
                        const matches = text.match(frontmatterRegex);

                        if (matches) {
                            const frontmatter = matches[1];
                            const metadata: any = {};
                            frontmatter.split('\n').forEach(line => {
                                const [key, value] = line.split(': ');
                                if (key && value) {
                                    metadata[key.trim()] = value.trim();
                                }
                            });

                            return {
                                slug: filename.replace('.md', ''),
                                title: metadata.title || 'Untitled',
                                date: metadata.date || new Date().toISOString(),
                                description: metadata.description || ''
                            };
                        }
                        return null;
                    } catch (error) {
                        console.error(`Error processing ${filename}:`, error);
                        return null;
                    }
                });

                const loadedPosts = (await Promise.all(postPromises))
                    .filter((post): post is BlogPost => post !== null)
                    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

                console.log('Loaded posts:', loadedPosts); // Debug log
                setPosts(loadedPosts);
            } catch (error) {
                console.error('Error loading posts:', error);
                setError(error instanceof Error ? error.message : 'Failed to load posts');
            } finally {
                setLoading(false);
            }
        };

        fetchPosts();
    }, []);

    const formatDate = (date: string) => {
        return new Date(date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    if (loading) {
        return (
            <div className="services-page">
                <section className="service">
                    <h2>Loading posts...</h2>
                </section>
            </div>
        );
    }

    if (error) {
        return (
            <div className="services-page">
                <section className="service">
                    <h2>Error loading posts</h2>
                    <p className="error-message">{error}</p>
                    <p>Please check:</p>
                    <ul className="debug-list">
                        <li>index.json exists in public/content/posts/</li>
                        <li>index.json contains a "posts" array</li>
                        <li>All referenced .md files exist</li>
                    </ul>
                </section>
            </div>
        );
    }

    return (
        <div className="services-page">
            <h1 className="blog-title">Blog Posts</h1>
            {posts.length === 0 ? (
                <section className="service">
                    <h2>No posts found</h2>
                    <p>Make sure you have:</p>
                    <ul className="debug-list">
                        <li>Created /public/content/posts/index.json</li>
                        <li>Added your .md files to /public/content/posts/</li>
                        <li>Listed your .md files in index.json</li>
                    </ul>
                </section>
            ) : (
                posts.map((post) => (
                    <section key={post.slug} className="service">
                        <h2>{post.title}</h2>
                        <p className="post-date">{formatDate(post.date)}</p>
                        <p className="post-description">{post.description}</p>
                        <Link to={`/blog/${post.slug}`} className="submit-btn">
                            Read More
                        </Link>
                    </section>
                ))
            )}
        </div>
    );
};

export default Blog;
