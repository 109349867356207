// BlogPost.tsx
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useParams, Link } from 'react-router-dom';
import './Blog.css';

interface PostMetadata {
    title: string;
    date: string;
    description: string;
    content: string;
}

const BlogPost = () => {
    const { slug } = useParams();
    const [post, setPost] = React.useState<PostMetadata | null>(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        const fetchPost = async () => {
            try {
                if (!slug) {
                    setPost(null);
                    return;
                }

                const response = await fetch(`/content/posts/${slug}.md`);
                if (!response.ok) {
                    throw new Error('Post not found');
                }

                const text = await response.text();
                const frontmatterRegex = /^---\n([\s\S]*?)\n---\n([\s\S]*)$/;
                const matches = text.match(frontmatterRegex);

                if (matches) {
                    const [, frontmatter, content] = matches;
                    const metadata: any = {};
                    frontmatter.split('\n').forEach(line => {
                        const [key, value] = line.split(': ');
                        if (key && value) {
                            metadata[key.trim()] = value.trim();
                        }
                    });

                    setPost({
                        title: metadata.title || 'Untitled',
                        date: metadata.date || new Date().toISOString(),
                        description: metadata.description || '',
                        content: content.trim()
                    });
                }
            } catch (error) {
                console.error('Error loading post:', error);
                setPost(null);
            } finally {
                setLoading(false);
            }
        };

        fetchPost();
    }, [slug]);

    const formatDate = (date: string) => {
        return new Date(date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    if (loading) {
        return (
            <div className="services-page">
                <section className="service">
                    <h2>Loading...</h2>
                </section>
            </div>
        );
    }

    if (!post) {
        return (
            <div className="services-page">
                <section className="service">
                    <h2>Post not found</h2>
                    <Link to="/blog" className="back-link">
                        ← Back to all blogs
                    </Link>
                </section>
            </div>
        );
    }

    return (
        <div className="services-page">
            <section className="service">
                <Link to="/blog" className="back-link">
                    ← Back to blog
                </Link>
                <h1 className="post-title">{post.title}</h1>
                <p className="post-date">{formatDate(post.date)}</p>
                <p className="post-description">{post.description}</p>
                <div className="blog-content">
                    <ReactMarkdown>{post.content}</ReactMarkdown>
                </div>
            </section>
        </div>
    );
};

export default BlogPost;
