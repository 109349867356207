import React from 'react';
import './Services.css'; // Make sure you have the Services.css file

const Services: React.FC = () => {
    return (
        <div className="services-page">
            <section className="service">
                <h2>Server Lösungen für kleine {"(<10)"} Unternehmen</h2>
                <p>Auf Sie persönlich zugeschnittene Serverlösungen, um die Effizienz und Sicherheit Ihres Unternehmens zu steigern.
                    Seit Beginn der Covid-19 Pandemie ist Remote Work zu einem wichtigen Bestandteil des Arbeitsalltags geworden.
                    Leider sind viele Unternehmen nicht auf diese Umstellung vorbereitet und haben mit Sicherheitslücken und
                    Performanceproblemen zu kämpfen. Wir helfen Ihnen, diese Probleme zu lösen und Ihre Serverinfrastruktur
                    aufzubauen, damit Sie sich auf Ihr Kerngeschäft konzentrieren können.
                </p>
            </section>

            <div className="service contact-container">
                <h2>Kontakt</h2>
                <form>
                    <div className="form-group">
                    </div>
                    <div className="form-group">
                    </div>
                    <div className="form-group">
                    </div>
                    <button type="submit" className="submit-btn">Send</button>
                </form>
            </div>
        </div>
    );
};

export default Services;

